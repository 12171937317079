export const state = {
  now: +new Date(),
  interval: null,
}

export const mutations = {
  SET_INTERVAL (state, payload) {
    state.interval = setInterval(() => {
      state.now += 1000
    }, 1000)
  },
}

export const actions = {
  interval ({ commit }) {
    commit('SET_INTERVAL')
  },
}

export const getters = {
  now: state => state.now,
}
