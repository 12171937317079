import Vue from 'vue'
import Vuetify from 'vuetify/lib'
import i18n from '@/i18n'
import '@/sass/overrides.sass'
import '@/sass/custom.sass'
// import CustomIcon from '@/components/IconComponent'
import camelCase from 'lodash/camelCase'

const reqireIcons = require.context(
  '@/components/customicons',
  false,
  /.*\.vue$/,
)
const iconsModules = {}
for (const file of reqireIcons.keys()) {
  const fileName = file.replace('.vue', '').replace('./', '')
  const component = require('@/components/customicons/' + fileName + '.vue')
  iconsModules[camelCase(fileName)] = {
    component: component.default,
    // props: { color: 'primary' },
  }
}

Vue.use(Vuetify)

const theme = {
  primary: '#7a00cc',
  secondary: '#E4009D',
  accent: '#FF1B6D',
  info: '#FF7C49',
  bright: '#FFBF43',
  light: '#F9F871',
}

export default new Vuetify({
  lang: {
    t: (key, ...params) => i18n.t(key, params),
  },
  rtl: true,
  theme: {
    themes: {
      dark: theme,
      light: theme,
    },
  },
  icons: {
    values: iconsModules,
  },
})
