/**
 * Initial state
 */
export const state = {
  drawer: null,
  barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
  barImage: '',
}

/**
 * Mutations
 */
export const mutations = {
  SET_BAR_IMAGE (state, payload) {
    state.barImage = payload
  },
  SET_DRAWER (state, payload) {
    state.drawer = payload
  },
}

/**
 * Actions
 */
export const actions = {
  setDrawer ({ commit }, drawer) {
    commit('SET_DRAWER', drawer)
  },
}

/**
 * Getters
 */
export const getters = {
  drawer: state => state.drawer,
  barColor: state => state.barColor,
  barImage: state => state.barImage,
}
