<template>
  <!--  -->
  <v-text-field
    ref="input"
    v-model="value"
    :label="label"
    :loading="loading"
    :placeholder="placeholder"
    :autocomplete="autocomplete"
    :max="max ? max : null"
    :maxlength="max ? max : null"
    :rules="rules"
    :validate-on-blur="lazy"
    :type="show ? 'text' : type"
    :clearable="clearable"
    :counter="showCounter"
    :dir="computedDir"
    :style="style"
    :class="classes"
    :hint="hint"
    :disabled="disabled || loading"
    :solo="solo"
    :outlined="outlined"
    :background-color="backgroundColor"
    :persistent-placeholder="persistentPlaceholder"
    :append-icon="icon"
    @click:append="show = !show"
    @keyup="click"
    @focus="focused = true"
    @blur="focused = false"
  />
</template>

<script>
export default {
  name: 'SmartInput',

  props: {
    type: {
      type: [String, null],
      default: 'text',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    solo: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    'background-color': {
      type: String,
      default: '',
    },
    'persistent-placeholder': {
      type: Boolean,
      default: false,
    },
    autocomplete: {
      type: String,
      default: 'on',
    },
    styles: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    model: {
      type: [String, Number],
      default: '',
    },
    numeric: {
      type: Boolean,
      default: false,
    },
    length: {
      type: [Number, String],
      default: null,
    },
    max: {
      type: [Number, String],
      default: null,
    },
    min: {
      type: [Number, String],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    counter: {
      type: [Boolean, Number],
      default: false,
    },
    lazy: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: false,
    },
    dir: {
      type: String,
      default: '',
    },
    regex: {
      type: RegExp,
      default: RegExp,
    },
    regexMessage: {
      type: String,
      default: '',
    },
    centered: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    compare: {
      type: [Number, String],
      default: null,
    },
    compareName: {
      type: String,
      default: '',
    },
    hint: {
      type: String,
      default: '',
    },
    'max-width': {
      type: [Number, String],
      default: '',
    },
  },

  data: () => ({
    value: undefined,
    initiated: false,
    focused: false,
    show: false,
  }),

  computed: {
    computedDir () {
      if (!this.dir) {
        return this.type === 'email' ? 'ltr' : this.$vuetify.rtl ? 'rtl' : 'ltr'
      }
      if (this.dir === 'ltr' && this.$vuetify.rtl) {
        return this.value ? 'ltr' : 'rtl'
      }
      return this.dir
    },
    rules () {
      const rules = []
      if (this.compare) {
        rules.push(
          v => v == this.compare || 'חייב להיות זהה ל' + this.compareName,
        )
      }
      if (this.required || this.min === 0) {
        rules.push(v => !!v || this.$t('form.required'))
      }
      if (this.type === 'email') {
        rules.push(
          v => !v || this.validateEmail(v) || this.$t('form.mustbeemail'),
        )
      }
      if (this.numeric || this.type === 'tel') {
        rules.push(v => !v || !isNaN(v) || this.$t('form.numeric'))
      }
      if (this.regex) {
        rules.push(v => this.regex.test(v) || this.$t(this.regexMessage))
      }
      if (this.length) {
        rules.push(
          v =>
            !v ||
            v.length === parseInt(this.length) ||
            this.$phraser.set(
              'must be exactly ' + parseInt(this.length) + ' characters',
            ),
        )
      } else {
        if (this.max) {
          rules.push(
            v =>
              !v ||
              v.length <= parseInt(this.max) ||
              this.$phraser.set(
                'cant be longer than ' + parseInt(this.max) + ' characters',
              ),
          )
        }
        if (this.min) {
          rules.push(
            v =>
              !v ||
              v.length >= parseInt(this.min) ||
              this.$phraser.set(
                'cant be shorter ' + parseInt(this.min) + ' characters',
              ),
          )
        }
      }
      return rules
    },
    showCounter () {
      const counted = !!this.length || !!this.max || !!this.min
      return counted && this.focused && this.counter
    },
    icon () {
      return this.type === 'password'
        ? this.show
          ? 'mdi-eye'
          : 'mdi-eye-off'
        : null
    },
    classes () {
      let classes = 'smart-input' + this.styles + ''
      if (this.centered) classes += ' text-center'
      return classes
    },
    style () {
      let style = ''
      if (this.maxWidth) style += ` max-width:${this.maxWidth}px;`
      return style
    },
    toConfirm () {
      return this.confirm
    },
  },
  watch: {
    model (model) {
      this.value = String(this.model)
      this.$refs.input.resetValidation()
    },
    // model (model, old) {
    // console.log('model', model, old)
    // if (!this.initiated) {
    //   console.log('initiate!')
    //   this.value = String(model)
    //   this.$refs.input.resetValidation()
    //   this.initiated = true
    // }
    // },
    value (value) {
      this.$emit('update:model', value)
    },
    // focused (value) {
    //   console.log('focused', value)
    // },
  },

  mounted () {
    this.value = String(this.model)
    this.$refs.input.resetValidation()
  },
  methods: {
    isType (type) {
      if (type === 'email') return this.isEmail
      if (type === 'password') return this.isPassword
    },
    click () {
      // console.log('counter', this.showCounter)
    },
    validateEmail (email) {
      const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(String(email).toLowerCase())
    },
  },
}
</script>
<style>
.smart-input.v-text-field input[type='tel'][dir='rtl'] {
  unicode-bidi: plaintext;
  text-align: right;
}
</style>
