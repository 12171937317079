// import axios from 'axios'
// const API = axios.create({
//   baseURL: 'http://127.0.0.1:8000/',
//   headers: { 'X-Requested-With': 'XMLHttpRequest' },
//   withCredentials: true,
// })
import API from '@/api'

export const state = {
  slug: null,
  phone: '',
  code: '',
  temporary: '',
  modal: false,
  timeLeft: null,
  timerInterval: null,
  resendTimeLeft: null,
  resendTimerInterval: null,
}

export const mutations = {
  SET_SLUG (state, slug) {
    state.slug = slug
  },
  SET_PHONE (state, phone) {
    state.phone = phone
  },
  SET_TEMPORARY (state, temporary) {
    state.temporary = temporary
  },
  SET_CODE (state, code) {
    state.code = code
  },
  SET_MODAL (state, boolean) {
    state.modal = boolean
  },
  START_TIMER (state, seconds) {
    //  Code Valid Interval
    clearInterval(this.timerInterval)
    this.timerInterval = null
    state.timeLeft = 5 * 60
    this.timerInterval = setInterval(() => {
      state.timeLeft = state.timeLeft - 1
      if (state.timeLeft === 0) {
        clearInterval(this.timerInterval)
        this.timerInterval = null
      }
    }, 1000)
    //  Resend Interval
    clearInterval(this.resendTimerInterval)
    this.resendTimerInterval = null
    state.resendTimeLeft = seconds
    this.resendTimerInterval = setInterval(() => {
      state.resendTimeLeft = state.resendTimeLeft - 1
      if (state.resendTimeLeft === 0) {
        clearInterval(this.resendTimerInterval)
        this.resendTimerInterval = null
      }
    }, 1000)
  },
}

export const actions = {
  start ({ commit }, req) {
    commit('SET_TEMPORARY', '')
    API.post('/users/' + req.slug + '/new-phone', { phone: req.phone })
      .then(async res => {
        commit('SET_SLUG', req.slug)
        commit('SET_PHONE', req.phone)
        commit('SET_MODAL', true)
        commit('START_TIMER', 1 * 60)
      })
      .catch(err => {
        this.dispatch('alerts/setError', err.response)
      })
  },
  cancel ({ commit }) {
    commit('SET_MODAL', false)
    commit('SET_PHONE', '')
    commit('SET_SLUG', null)
    commit('SET_TEMPORARY', '')
    commit('SET_CODE', '')
  },
  async resend ({ commit, state }) {
    await API.post('/users/' + state.slug + '/new-phone', {
      phone: state.phone,
    })
      .then(async res => {
        commit('SET_MODAL', true)
        commit('START_TIMER', 1 * 60)
      })
      .catch(err => {
        this.dispatch('alerts/setError', err.response)
      })
  },
  async checkCode ({ commit, state }) {
    await API.post('/users/' + state.slug + '/new-phone/check-code', {
      code: state.code,
      phone: state.phone,
    })
      .then(async res => {
        commit('SET_TEMPORARY', res.data.data)
      })
      .catch(err => {
        this.dispatch('alerts/setError', err.response)
      })
  },
  setCode ({ commit }, code) {
    commit('SET_CODE', code)
  },
  close ({ commit }) {
    commit('SET_PHONE', '')
    commit('SET_CODE', '')
    commit('SET_MODAL', false)
  },
}

export const getters = {
  phone: state => state.phone,
  code: state => state.code,
  modal: state => state.modal,
  temporary: state => state.temporary,
  get: state => state,
}
