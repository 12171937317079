// import axios from 'axios'
// const API = axios.create({
//   baseURL: 'http://127.0.0.1:8000/',
//   headers: { 'X-Requested-With': 'XMLHttpRequest' },
//   withCredentials: true,
// })
import API from '@/api'

export const state = {
  auth: false,
  user: {},
  permissions: {},
}

export const mutations = {
  SET_USER (state, user) {
    state.user = user
  },
  UNSET_USER (state) {
    state.user = null
  },
  SET_AUTH (state, auth) {
    state.auth = auth
    setTimeout(() => this.dispatch('user/check'), 1000 * 60 * 3)
  },
  SET_PERMISSIONS (state, permissions) {
    state.permissions = permissions
  },
}

export const actions = {
  async getProfile ({ commit }) {},
  setToken ({ commit }, token) {
    commit('SET_ACCESS', token)
  },
  setUser ({ commit }, user) {
    commit('SET_USER', user)
  },
  check ({ commit }) {
    API.get('/auth/check')
      .then(res => {
        commit('SET_AUTH', true)
        commit('SET_USER', res.data.data.user)
        commit('SET_PERMISSIONS', res.data.data.permissions)
      })
      .catch(() => {
        commit('SET_AUTH', false)
        commit('SET_USER', {})
        commit('SET_PERMISSIONS', {})
      })
  },
  async authenticated ({ commit }, data) {
    commit('SET_AUTH', true)
    commit('SET_USER', data.user)
    commit('SET_PERMISSIONS', data.permissions)
  },
  setPushnots ({ commit }, value) {
    commit('SET_PUSHNOTS', value)
  },
}

export const getters = {
  auth: state => state.auth,
  user: state => state.user,
  permissions: state => state.permissions,
  can: state => ability =>
    state.permissions ? state.permissions[ability] : false,
}
