import Vue from 'vue'
import Router from 'vue-router'
import store from '@/store'
import PassThrough from '@/components/PassThrough'

Vue.use(Router)

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      beforeEnter (to, from, next) {
        store.getters['user/auth'] ? next() : next('/sign-in')
      },
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        // !!!!!!!!!! -------------- CLIENTS  --------------  !!!!!!!!!!
        {
          name: 'Clients',
          path: 'clients',
          component: PassThrough,
          meta: { breadCrumb: 'clients' },
          children: [
            {
              name: 'AllClient',
              path: '',
              component: () =>
                import('@/views/dashboard/pages/clients/Clients'),
            },
            {
              name: 'CreateClient',
              path: 'create',
              component: () =>
                import('@/views/dashboard/pages/clients/CreateClient'),
            },
            {
              name: 'Client',
              path: ':code',
              component: () => import('@/views/dashboard/pages/clients/Client'),
              meta: { breadCrumb: 'client' },
              redirect: { name: 'ClientDetails' },
              children: [
                {
                  name: 'ClientDetails',
                  path: 'details',
                  component: () =>
                    import('@/views/dashboard/pages/clients/Details'),
                },
                {
                  name: 'SubClients',
                  path: 'sub-clients',
                  meta: { breadCrumb: 'subclients' },
                  component: () =>
                    import('@/views/dashboard/pages/clients/SubClients'),
                },
                {
                  name: 'ClientUsers',
                  path: 'users',
                  component: () =>
                    import('@/views/dashboard/pages/clients/Users'),
                },
                {
                  name: 'ClientDevices',
                  path: 'devices',
                  component: () =>
                    import('@/views/dashboard/pages/clients/Devices'),
                },
              ],
            },
          ],
        },
        // !!!!!!!!!! -------------- USERS  --------------  !!!!!!!!!!
        {
          name: 'Users',
          path: 'users',
          component: PassThrough,
          meta: { breadCrumb: 'users' },
          redirect: { name: 'AllUsers' },
          children: [
            {
              name: 'AllUsers',
              path: '',
              component: () => import('@/views/dashboard/pages/users/users'),
            },
            {
              name: 'CreateUser',
              path: 'create',
              component: () =>
                import('@/views/dashboard/pages/users/CreateUser'),
            },
            {
              name: 'User',
              path: ':slug',
              component: () => import('@/views/dashboard/pages/users/User'),
              meta: { breadCrumb: 'user' },
              redirect: { name: 'UserDetails' },
              children: [
                {
                  name: 'UserDetails',
                  path: 'details',
                  component: () =>
                    import('@/views/dashboard/pages/users/Details'),
                },
                {
                  name: 'UserDevices',
                  path: 'devices',
                  component: () =>
                    import('@/views/dashboard/pages/users/Devices'),
                },
              ],
            },
          ],
        },
        // !!!!!!!!!! -------------- DEVICES  --------------  !!!!!!!!!!
        {
          name: 'Devices',
          path: 'devices',
          component: PassThrough,
          meta: { breadCrumb: 'devices' },
          redirect: { name: 'AllDevices' },
          children: [
            {
              name: 'AllDevices',
              path: '',
              component: () =>
                import('@/views/dashboard/pages/devices/devices'),
            },
            {
              name: 'Device',
              path: ':slug',
              component: () => import('@/views/dashboard/pages/devices/Device'),
              meta: { breadCrumb: 'device' },
              redirect: { name: 'DeviceDetails' },
              children: [
                {
                  name: 'DeviceDetails',
                  path: 'details',
                  component: () =>
                    import('@/views/dashboard/pages/devices/Details'),
                },
                {
                  name: 'DeviceSettings',
                  path: 'settings',
                  component: () =>
                    import('@/views/dashboard/pages/devices/Settings'),
                },
                {
                  name: 'DeviceLogs',
                  path: 'logs',
                  component: () =>
                    import('@/views/dashboard/pages/devices/Logs'),
                },
                {
                  name: 'DeviceUsers',
                  path: 'users',
                  component: () =>
                    import('@/views/dashboard/pages/devices/Users'),
                },
              ],
            },
          ],
        },
        {
          name: 'User Profile',
          path: 'pages/user',
          component: () =>
            import('@/views/dashboard/pages/profile/UserProfile'),
          children: [
            {
              name: 'ProfileDetails',
              path: 'details',
              component: () =>
                import('@/views/dashboard/pages/profile/Details'),
            },
            {
              name: 'ProfileDevices',
              path: 'devices',
              component: () =>
                import('@/views/dashboard/pages/profile/Devices'),
            },
          ],
        },
        {
          name: 'Notifications',
          path: 'components/notifications',
          component: () => import('@/views/dashboard/component/Tabs'),
        },
        {
          name: 'Icons',
          path: 'components/icons',
          component: () => import('@/views/dashboard/component/Icons'),
        },
        {
          name: 'Typography',
          path: 'components/typography',
          component: () => import('@/views/dashboard/component/Typography'),
        },
        // Tables
        {
          name: 'Regular Tables',
          path: 'tables/regular-tables',
          component: () => import('@/views/dashboard/tables/RegularTables'),
        },
        // Maps
        {
          name: 'Google Maps',
          path: 'maps/google-maps',
          component: () => import('@/views/dashboard/maps/GoogleMaps'),
        },
        // Test Page
        {
          name: 'Test',
          path: '/test',
          component: () => import('@/views/dashboard/dash2'),
        },
        // Sign Out
        {
          name: 'SignOut',
          path: '/signout',
          component: () => import('@/views/Signout'),
        },
      ],
    },
    {
      path: '/sign-in',
      component: () => import('@/views/gate/Index'),
      beforeEnter (to, from, next) {
        !store.getters['user/auth'] ? next() : next('/')
      },
      children: [
        {
          name: 'SignIn',
          path: '',
          component: () => import('@/views/gate/SignIn'),
        },
      ],
    },
    {
      path: '/*',
      name: 'PageNotFound',
      component: () => import('@/views/errors/error404'),
    },
  ],
})
