import i18n from '@/i18n'

class Phraser {
  set (words, title_case = true) {
    words = String(words)
    words = words.toLowerCase().split(' ')
    words = this.fixApostrophe(words)
    const count = words.length
    let phrase = words.map((word, i) => {
      const punctuation = this.getPunctuation(word)
      word = word.replace(punctuation, '')
      word = i18n.t(word) + punctuation
      if (word.endsWith('ה') && i < count - 1) word = this.fixLastA(word)
      return word
    })
    phrase = this.fixAdjective(phrase)
    phrase = this.fixAfterAll(phrase)
    phrase = phrase.join(' ')
    phrase = this.fixTheOrAnd(phrase)
    phrase = this.catchPhrases(phrase)
    return phrase
  }

  fixAdjective (str) {
    const adjectives = [
      'מחובר',
      'מחוברים',
      'מזהה',
      'חדש',
      'קשר',
      'הזה',
      'ראשון',
      'אחרון',
    ]
    let skip = false
    for (let i = 0; i < str.length; i++) {
      if (!skip && adjectives.includes(str[i])) {
        const adj = str[i]
        str[i] = str[i + 1]
        str[i + 1] = adj
        skip = true
      }
    }
    return str
  }

  fixLastA (word) {
    const ignore = ['אתה', 'הזה', 'גישה']
    if (ignore.some(substring => word.includes(substring))) return word
    const place = word.lastIndexOf('ה')
    if (place === 0 || word.lastIndexOf('הה') >= 0) return word
    return word.substring(0, place) + 'ת'
  }

  fixAfterAll (words) {
    const i = words.indexOf('כל')
    if (i >= 0) words.splice(i + 1, 0, 'ה')
    return words
  }

  fixApostrophe (words) {
    if (i18n.locale === 'he' && words.join('').includes("'s")) {
      const i = words.findIndex(word => word.includes("'s"))
      const withApostrophe = words[i]
      words[i] = words[i + 1]
      words[i + 1] = withApostrophe.replace("'s", '')
      words.splice(i + 1, 0, 'the')
    }
    return words
  }

  getPunctuation (word) {
    if (word.endsWith('.')) return '.'
    if (word.endsWith(',')) return ','
    if (word.endsWith(':')) return ':'
    if (word.endsWith('/')) return '/'
    if (word.endsWith('\\')) return '\\'
    if (word.endsWith("'")) return "'"
    return ''
  }

  catchPhrases (phrase) {
    const CATCHES = {
      'WiFi בקר': 'בקר WiFi',
      'LTE בקר': 'בקר LTE',
      'BLE חיישן': 'חיישן BLE',
      'LTE חיישן': 'חיישן LTE',
      'SMS חבילה': 'חבילת SMS',
      'שם ראשון': 'שם פרטי',
      'שם אחרון': 'שם משפחה',
      'שם קשר': 'שם איש קשר',
      'טלפון קשר': 'טלפון ליצירת קשר',
    }
    for (const catchPhrase in CATCHES) {
      phrase = phrase.replace(catchPhrase, CATCHES[catchPhrase])
    }
    return phrase
  }

  fixTheOrAnd (phrase) {
    phrase = phrase.replace(' ה ', ' ה').split(' ')
    let i = phrase.findIndex(word => word === 'ה')
    if (i >= 0) {
      phrase[i + 1] = 'ה' + phrase[i + 1]
      delete phrase[i]
    }
    i = phrase.findIndex(word => word === 'ו')
    if (i >= 0) {
      phrase[i + 1] = 'ו-' + phrase[i + 1]
      delete phrase[i]
    }
    i = phrase.findIndex(word => word === 'ל')
    if (i >= 0) {
      phrase[i + 1] = 'ל' + phrase[i + 1]
      delete phrase[i]
    }
    i = phrase.findIndex(word => word === 'מ')
    if (i >= 0) {
      phrase[i + 1] = 'מ' + phrase[i + 1]
      delete phrase[i]
    }
    phrase = phrase.join(' ')
    return phrase
  }
}

export default new Phraser()
