import Vue from 'vue'
// import axios from 'axios'
// const API = axios.create({
//   baseURL: 'http://127.0.0.1:8000/',
//   headers: { 'X-Requested-With': 'XMLHttpRequest' },
//   withCredentials: true,
// })
import API from '@/api'

/**
 * Initial state
 */
export const state = {
  users_devices: {},
  loading: true,
}

/**
 * Mutations
 */
export const mutations = {
  SET_USERS_DEVICES (state, users_devices) {
    state.users_devices = users_devices
    state.loading = false
  },

  SET_USER_DEVICES (state, { email, devices }) {
    Vue.set(state.users_devices, email, devices)
  },
}

/**
 * Actions
 */
export const actions = {
  async get ({ commit }) {
    API.get('/users-devices')
      .then(res => {
        commit('SET_USERS_DEVICES', res.data.data)
      })
      .catch(err => {
        this.dispatch('alerts/setError', err.response)
      })
  },

  async editUser ({ commit }, { slug, devices }) {
    await API.post(`/users/${slug}/devices`, devices)
      .then(async res => {
        await commit('SET_USER_DEVICES', {
          // email: slug.replaceAll('~', '.'),
          email: slug.split('~').join('.'),
          devices: res.data.data,
        })
        this.dispatch('alerts/setSuccess', {
          message: 'רשימת המכשירים של המשתמש עודכנה',
        })
      })
      .catch(err => {
        console.log(err)
        this.dispatch('alerts/setError', err.response)
      })
  },
}

/**
 * Getters
 */
export const getters = {
  loading: state => state.loading,
  users_devices: state => state.users_devices,
  getUser: state => email => state.users_devices[email],
  getByUsers: state => users => {
    let users_devices = []
    for (const email of users.map(u => u.email)) {
      let devices = state.users_devices[email]
      if (devices) {
        devices = devices.map(x => x.device)
        users_devices = [...users_devices, ...devices]
      }
    }
    return users_devices
  },
  getByDevice: state => identifier => {
    const emails = Object.keys(state.users_devices).filter(email => {
      return state.users_devices[email].some(
        device => device.device === identifier,
      )
    })
    const deviceUsers = {}
    for (const email of emails) {
      deviceUsers[email] = state.users_devices[email].find(device => {
        return device.device === identifier
      })
    }
    return deviceUsers
  },
  countUsersByIdentifier: (state, getters) => identifier => {
    return Object.keys(getters.getByDevice(identifier)).length
  },
}
