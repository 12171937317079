import axios from 'axios'

const API = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? 'http://localhost:8000/'
      : 'https://backend.cp.switchq.com/',
  headers: { 'X-Requested-With': 'XMLHttpRequest' },
  withCredentials: true,
})

export default API
