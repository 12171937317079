// =========================================================
// * Vuetify Material Dashboard - v2.1.0
// =========================================================
// * Product Page: https://www.creative-tim.com/product/vuetify-material-dashboard
// * Copyright 2019 Creative Tim (https://www.creative-tim.com)
// * Coded by Creative Tim
// =========================================================
// * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

import Vue from 'vue'
// import axios from 'axios'
import App from './App.vue'
import router from './router'
import store from './store/'
import './plugins/base'
import './plugins/chartist'
import './plugins/vee-validate'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import Phraser from './helpers/phraser'
// store.dispatch('settings/setBaseURL', Vue.prototype.baseURL)
import Api from './api'

window.TESTING_LOCAL = false

Vue.prototype.$phraser = Phraser

Vue.config.productionTip = false

// Vue.prototype.baseURL = 'http://localhost:8000/'
// console.log('Api', Api)

Vue.prototype.$api = Api
// Vue.prototype.$api = axios.create({
//   baseURL: Vue.prototype.baseURL,
//   headers: { 'X-Requested-With': 'XMLHttpRequest' },
//   withCredentials: true,
// })
Api.get('/auth/check')
  .then(res => {
    store.dispatch('user/authenticated', res.data.data)
  })
  .catch(() => {
    // console.error('Not authenticated')
  })
  .finally(() => {
    new Vue({
      router,
      store,
      vuetify,
      i18n,
      render: h => h(App),
    }).$mount('#app')
  })
