import axios from 'axios'
// const API = axios.create({
//   baseURL: 'http://127.0.0.1:8000/',
//   headers: { 'X-Requested-With': 'XMLHttpRequest' },
//   withCredentials: true,
// })

const TEMPLATES_TYPES_COUNT = 5

export const state = {
  templates: [
    // EXACT VALUES
    {
      field: 'email',
      components: ['users/searchByEmail', 'clients/searchByEmail'],
      template: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
    },
    {
      field: 'phone',
      components: ['users/searchByPhone', 'clients/searchByPhone'],
      template: /^\d{9,10}$/,
    },
    {
      field: 'client_code',
      components: ['clients/searchByCode'],
      template: /^\d{6}$/,
    },
    {
      field: 'device',
      components: ['devices/searchById'],
      template: /^[\dA-Z]{12}$/,
    },

    // PART OF VALUE
    {
      field: 'name',
      components: ['users/searchByName', 'clients/searchByName'],
      template: /\w+/,
    },
    {
      field: 'email',
      components: ['users/searchByEmail', 'clients/searchByEmail'],
      template: /.+@/,
    },
    {
      field: 'phone',
      components: ['users/searchByPhone', 'clients/searchByPhone'],
      template: /^05[2,3,4,5,6,7,8,9]{1,10}/,
    },
    {
      field: 'client_code',
      components: ['clients/searchByCode'],
      template: /^\d{1,6}$/,
    },
    {
      field: 'device',
      components: ['devices/searchById'],
      template: /^[A-Z\d]+$/,
    },
  ],
}

export const mutations = {}

export const actions = {
  search ({ commit, dispatch }, searchInput) {
    return new Promise(async (resolve, reject) => {
      try {
        const prioritized_components = await dispatch(
          'prioritize_search',
          searchInput,
        )
        const results = await dispatch('fetch_results', {
          prioritized_components,
          searchInput,
        })
        resolve(results)
      } catch (err) {
        console.error(err)
        resolve([])
      }
    })
  },

  prioritize_search ({ state }, searchInput) {
    const prioritized_components = []

    // insert template if has match
    state.templates.forEach(item => {
      const matches = searchInput.match(item.template)
      if (matches && matches.length) {
        prioritized_components.push(item)
      }
    })

    // insert templates leftovers by order
    if (prioritized_components.length < TEMPLATES_TYPES_COUNT) {
      state.templates.forEach(template_item => {
        if (
          !prioritized_components.find(
            prioritized_item => prioritized_item.field === template_item.field,
          )
        ) {
          prioritized_components.push(template_item)
        }
      })
    }

    return prioritized_components
  },

  async fetch_results ({}, data) {
    let results = []
    for (
      let priority_index = 0;
      priority_index < data.prioritized_components.length;
      priority_index++
    ) {
      const item = data.prioritized_components[priority_index]

      for (
        let item_index = 0;
        item_index < item.components.length;
        item_index++
      ) {
        try {
          const component = item.components[item_index]
          const new_search_results = await this.dispatch(
            component,
            data.searchInput,
          )
          if (new_search_results && new_search_results.length) {
            results = results.concat(new_search_results)
          }
        } catch (err) {
          console.error(err)
        }
      }
    }

    return results
  },
}

export const getters = {}
