/**
 * Initial state
 */
export const state = {
  alerts: [],
}

/**
 * Mutations
 */
export const mutations = {
  SET_ERROR (state, error) {
    try {
      if (error && error.status === 422) {
        const errorsObj = error.data.errors
          ? error.data.errors
          : error.data.data
        for (const err in errorsObj) {
          let errors = errorsObj[err]
          if (typeof error === 'object') {
            errors = errors.map(e => e.replace(/\d+\./g, ''))
          } else {
            errors.replace(/\d+\./g, '')
          }
          const alertObj = {
            id: +new Date(),
            type: 'error',
            parameter: err,
            message: errors,
            displaytime: 30,
          }
          state.alerts.push(alertObj)
        }
      } else if (
        error &&
        error.status &&
        (error.status === 403 || error.status === 400)
      ) {
        const alertObj = {
          id: +new Date(),
          type: 'error',
          parameter: error,
          message: error.data.message,
          displaytime: 30,
        }
        state.alerts.push(alertObj)
      } else if (error && error.status && error.status === 429) {
        const alertObj = {
          id: +new Date(),
          type: 'error',
          parameter: error,
          message: 'errors.too_many_attempts',
          displaytime: 60,
        }
        state.alerts.push(alertObj)
      }
    } catch (e) {
      console.error(error)
      console.error(e)
      const alertObj = {
        id: +new Date(),
        type: 'error',
        parameter: error,
        message: 'משהו השתבש',
        displaytime: 60,
      }
      state.alerts.push(alertObj)
    }
  },
  SET_SUCCESS (state, res) {
    const alertObj = {
      id: +new Date(),
      type: 'success',
      parameter: 'success',
      message: res.message,
      displaytime: 10,
    }
    state.alerts.push(alertObj)
  },
  DELETE_ALERT (state, id) {
    state.alerts = state.alerts.filter(error => error.id !== id)
  },
}

/**
 * Actions
 */
export const actions = {
  setError ({ commit }, error) {
    commit('SET_ERROR', error)
  },
  setSuccess ({ commit }, success) {
    commit('SET_SUCCESS', success)
  },
  deleteAlert ({ commit }, id) {
    commit('DELETE_ALERT', id)
  },
}

/**
 * Getters
 */
export const getters = {
  all: state => state.alerts,
}
