// import axios from 'axios'
// import Vue from 'vue'
import API from '@/api'
// console.log(API)
// const API = axios.create({
//   baseURL: 'http://127.0.0.1:8000/',
//   headers: { 'X-Requested-With': 'XMLHttpRequest' },
//   withCredentials: true,
// })
const MAIN_COLOR = '#2196f3'
const TESTING_LOCAL = false

function flattenClients (clients, flattened) {
  for (const client in clients) {
    // console.log(clients[client])
    if (
      clients[client].clients &&
      Object.keys(clients[client].clients).length
    ) {
      flattenClients(clients[client].clients, flattened)
    }
    // flattened.push(client)
    flattened[client] = clients[client]
  }
}

/**
 * Initial state
 */
export const state = {
  clients: [],
  // baseClients: [],
  // allClients: [],
  // counters: {
  //   clients: 0,
  //   subClients: 0
  // },
  self: {},
  loading: true,
}

/**
 * Mutations
 */
export const mutations = {
  SET_CLIENTS (state, clients) {
    const flattened = {}
    flattenClients(clients, flattened)
    state.clients = flattened
    state.loading = false
  },

  SET_SELF (state, { ...self }) {
    state.self = self
    // state.allClients = null
    // state.allClients = []
    // state.allClients.push(self)
  },

  // SCAN_ALL_CLIENTS (state, clients) {
  //   state.counters.clients = Object.keys(clients).length
  //   let totalSubClients = 0
  //   scanAllSubClients(clients)
  //
  //   function scanAllSubClients(clients) {
  //     Object.keys(clients).map((key, index) => {
  //       totalSubClients++
  //
  //       const newClient = clients[key]
  //       newClient.parent = state.self.name
  //       state.allClients.push(newClient)
  //
  //       if (newClient.clients) {
  //         scanAllSubClients(clients[key].clients)
  //       }
  //     })
  //   }
  //
  //   state.counters.subClients = totalSubClients - state.counters.clients
  // },

  // SET_CLIENT (state, client) {
  //   const index = state.allClients.findIndex(c => c.code === client.code)
  //   client.parent = state.self.name
  //   Vue.set(state.allClients, index, client)
  // },

  SET_NEW_CLIENT (state, newClient) {
    console.log('bef', state.clients)
    scanAllSubClients(state.clients, newClient)

    function scanAllSubClients (clients, newClient) {
      Object.keys(clients).map((key, index) => {
        if (newClient.parent_client === clients[key].code) {
          if (clients[key].clients) {
            clients[key].clients[newClient.code] = newClient
          } else {
            clients[key].clients = {
              [newClient.code]: newClient,
            }
          }
        }

        if (clients[key].clients) {
          scanAllSubClients(clients[key].clients, newClient)
        }
      })
    }

    console.log('af', state.clients)
  },
}

/**
 * Actions
 */
export const actions = {
  async get ({ commit }) {
    if (TESTING_LOCAL) {
      const clients = localStorage.getItem('clients')
      if (clients) {
        return commit('SET_CLIENTS', JSON.parse(clients))
      }
    }

    API.get('/clients')
      .then(async res => {
        commit('SET_SELF', res.data.data.meta_data)
        commit('SET_CLIENTS', res.data.data.clients)
        if (TESTING_LOCAL) {
          localStorage.setItem('clients', JSON.stringify(res.data.data.clients))
        }
        // commit('SCAN_ALL_CLIENTS', res.data.data.clients)
      })
      .catch(err => {
        console.log(err)
      })
  },
  // TODO::update vuex state, remove the get request
  async editClient ({ commit, dispatch }, client) {
    await API.post('/clients/' + client.code + '/edit', client)
      .then(res => {
        commit('SET_CLIENT', res.data.data)
        dispatch('get')
        this.dispatch('alerts/setSuccess', {
          message: 'The client has been edited successfully',
        })
      })
      .catch(err => {
        console.log('err', err)
        this.dispatch('alerts/setError', err.response)
      })
  },
  // TODO::update vuex state, remove the get request
  async createClient ({ commit, dispatch }, client) {
    await API.post('/clients/create', client)
      .then(res => {
        // const newClient = res.data.data
        // newClient.parent_client = client.parent_client
        // commit('SET_NEW_CLIENT', newClient)
        dispatch('get')
        this.dispatch('alerts/setSuccess', {
          message: 'The client has been created successfully',
        })
      })
      .catch(err => {
        console.log('err', err)
        this.dispatch('alerts/setError', err.response)
      })
  },

  searchByInput ({ state }, data) {
    const results = []
    for (const client in state.clients) {
      if (client && client[data.field]) {
        const field = client[data.field].toLowerCase()
        if (field.includes(data.searchInput)) {
          const text =
            data.field === 'name' ? field : field + ` (${client.name})`
          results.push({
            text: text,
            color: MAIN_COLOR,
            component: 'Client',
            type: `Client's ${data.field}`,
            path: `/clients/${client.slug}/details`,
          })
        }
      }
    }
    return results
  },

  async searchByEmail ({ dispatch }, searchInput) {
    return await dispatch('searchByInput', { searchInput, field: 'email' })
  },

  async searchByName ({ dispatch }, searchInput) {
    return await dispatch('searchByInput', { searchInput, field: 'name' })
  },

  async searchByPhone ({ dispatch }, searchInput) {
    return await dispatch('searchByInput', { searchInput, field: 'phone' })
  },

  async searchByCode ({ dispatch }, searchInput) {
    return await dispatch('searchByInput', { searchInput, field: 'code' })
  },
}

/**
 * Getters
 */
export const getters = {
  loading: state => state.loading,
  self: state => state.self,
  clients: state => state.clients,
  baseClients: state =>
    Object.values(state.clients).filter(client => {
      return client.parent_client === state.self.code
    }),
  countAllClients: state => Object.keys(state.clients).length,
  countBaseClients: state =>
    Object.values(state.clients).filter(client => {
      return client.parent_client === state.self.code
    }).length,
  countSubClients: state =>
    Object.values(state.clients).filter(client => {
      return client.parent_client !== state.self.code
    }).length,
  client: state => code => {
    if (code == state.self.code) return state.self
    return state.clients[code]
  },
  clientsNames: state => {
    const names = {}
    for (const client of Object.values(state.clients)) {
      names[client.code] = client.name
    }
    names[state.self.code] = state.self.name
    return names
  },
  all: state => {
    const clients = state.clients
    const self = state.self
    clients[self.code] = self
    return clients
  },
  clientsCodesNames: state => {
    const self = state.self
    const names = [{ value: self.code, text: self.name }]
    for (const client of Object.values(state.clients)) {
      names.push({ value: client.code, text: client.name })
    }
    return names
  },
}
