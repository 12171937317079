<template>
  <!--  -->
  <v-card
    :class="classes"
    :to="to"
    @mouseover="hover = true"
    @mouseleave="hover = false"
  >
    <v-card-title>
      <v-row class="d-flex justify-space-between align-center mb-2 px-2">
        <div class="d-flex">
          <v-avatar
            v-if="icon"
            size="90"
            class="elevation-6 ms-1"
            :color="color"
          >
            <v-icon v-if="!loading" dark size="50">
              {{ icon }}
            </v-icon>
            <v-icon v-else dark size="50">
              mdi-loading mdi-spin
            </v-icon>
          </v-avatar>
          <div class="d-flex flex-column">
            <div :class="`nice-card-title ms-5 text-h3 d-flex ${color_text}`">
              <span class="d-none d-sm-block">{{ $phraser.set(title) }}</span>
              <span v-if="name" class="ms-2">- {{ $phraser.set(name) }}</span>
            </div>
            <div
              :class="
                `nice-card-title ms-5 text-h4 d-flex ${subtitle_color_text}`
              "
            >
              <span class="d-none d-sm-block">{{
                $phraser.set(subtitle)
              }}</span>
              <span v-if="subtitle_value" class="ms-2">{{
                $phraser.set(subtitle_value)
              }}</span>
            </div>
          </div>
        </div>
      </v-row>
    </v-card-title>
    <v-divider v-if="parameters.length" class="mt-1" />
    <v-card-text class="pa-3 mt-1">
      <v-row justify="space-between">
        <v-col
          v-for="(param, index) in parameters"
          :key="index"
          cols="12"
          :md="param.main ? 12 : 6"
          :class="`pt-0 pb-1 ${parameters_color_text}`"
        >
          <v-icon
            v-if="param.icon"
            :size="(6 - param.size) * 10"
            class="me-1"
            :color="parameters_color"
          >
            {{ param.icon }}
          </v-icon>
          <strong :class="textSize(param.size ? param.size + 1 : 4)">
            {{ $phraser.set(param.key) }}:&nbsp;
          </strong>
          <span>{{ $phraser.set(param.value) }}</span>
        </v-col>
      </v-row>
      <!--  -->
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NiceCard',

  props: {
    type: {
      type: String,
      default: '',
    },
    to: {
      type: [String, Object],
      default: null,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: '',
    },
    color: {
      type: String,
      default: 'primary',
    },
    title: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    subtitle: {
      type: String,
      default: '',
    },
    subtitle_value: {
      type: [String, Number],
      default: '',
    },
    subtitle_color: {
      type: String,
      default: 'secondary',
    },
    parameters_color: {
      type: String,
      default: 'info',
    },
    parameters: {
      type: Array,
      default: () => [],
    },
  },

  data: () => ({
    hover: false,
  }),

  computed: {
    classes () {
      let classes = 'nice-card'
      if (this.to) classes += ' clickable'
      if (this.hover) classes += ' elevation-5'
      return classes
    },
    color_text () {
      const color = this.color.split(' ')
      return `${color[0]}--text text--${color[1]}`
    },
    subtitle_color_text () {
      const color = this.subtitle_color.split(' ')
      return `${color[0]}--text text--${color[1]}`
    },
    parameters_color_text () {
      const color = this.parameters_color.split(' ')
      return `${color[0]}--text text--${color[1]}`
    },
    //
  },
  mounted () {
    //
  },
  methods: {
    // mouseover (hover) {
    //   console.log(hover)
    // },
    textSize (size) {
      switch (parseInt(size)) {
        case 1:
          return 'text-h1'
        case 2:
          return 'text-h2'
        case 3:
          return 'text-h3'
        case 4:
          return 'text-h4'
        case 5:
          return 'text-h5'
        default:
          return 'text-h3'
      }
    },
  },
}
</script>

<style lang="sass" scoped>
.nice-card
  min-height: 122px
.nice-card.clickable
  transition: transform .2s
  &:hover
    transform: scale(1.05)
    shad
</style>
