import colors from 'vuetify/lib/util/colors'
export default {
  computed: {
    color () {
      if (this.$parent.dark || this.$parent.isDark) return '#ffffff'
      if (this.$parent.color) {
        const COLOR = this.$parent.color.split(' ')
        if (COLOR[1]) COLOR[1] = COLOR[1].replace('-', '')
        const theme = this.$vuetify.theme.isDark
          ? this.$vuetify.theme.themes.dark
          : this.$vuetify.theme.themes.light
        if (theme[COLOR[0]]) {
          if (COLOR[1] && theme[COLOR[0]][COLOR[1]]) {
            return theme[COLOR[0]][COLOR[1]]
          }
          return theme[COLOR[0]]
        }
        const color = colors[COLOR[0]]
        if (color && COLOR[1] && color[COLOR[1]]) return color[COLOR[1]]
        if (color) return color.base
      }
      return '#6d6d6d'
    },
  },
}
