import Vue from 'vue'
// import axios from 'axios'
// const API = axios.create({
//   baseURL: 'http://127.0.0.1:8000/',
//   headers: { 'X-Requested-With': 'XMLHttpRequest' },
//   withCredentials: true,
// })
import API from '@/api'

const MAIN_COLOR = '#9c27b0'
const TESTING_LOCAL = false

/**
 * Initial state
 */
export const state = {
  devices: [],
  usersDevices: [],
  device_types: {
    1: 'WiFi Controller',
    2: 'BLE Sensor',
    3: 'LTE Controller',
    4: 'LTE Sensor',
  },
  loading: true,
  tries: 0,
}

/**
 * Mutations
 */
export const mutations = {
  SET_DEVICES (state, devices) {
    const now = parseInt(new Date().getTime() / 1000)
    for (const identifier in devices) {
      devices[identifier].online = false
      if (devices[identifier].data && devices[identifier].data.received) {
        const received = parseInt(
          new Date(devices[identifier].data.received).getTime() / 1000,
        )
        if (now - received <= devices[identifier].settings.ttl * 60) {
          devices[identifier].online = true
        }
      }
    }
    state.devices = devices
  },
  SET_DEVICE (state, device) {
    try {
      device.device.identifier = device.identifier
      device = device.device
      const now = parseInt(new Date().getTime() / 1000)
      const received = parseInt(new Date(device.data.received).getTime() / 1000)
      device.online = now - received <= device.settings.ttl * 60
      Vue.set(state.devices, device.identifier, device)
    } catch (e) {
      console.log(e)
    }
  },
  SET_LOADING (state, boolean) {
    state.loading = boolean
  },
  SET_USERS_DEVICES (state, usersDevices) {
    state.usersDevices = []
    state.usersDevices = usersDevices
  },
  TRIED (state) {
    state.tries++
  },
}

/**
 * Actions
 */
export const actions = {
  async get ({ commit }) {
    if (TESTING_LOCAL) {
      const devices = localStorage.getItem('devices')
      if (devices) {
        return commit('SET_DEVICES', JSON.parse(devices))
      }
    }

    await API.get('/devices')
      .then(async res => {
        if (res.data && res.data.data) {
          await commit('SET_DEVICES', res.data.data)
          // if (TESTING_LOCAL) {
          //   localStorage.setItem('devices', JSON.stringify(res.data.data))
          // }
        } else {
          await API.get('/devices')
            .then(async res => {
              if (res.data && res.data.data) {
                await commit('SET_DEVICES', res.data.data)
              }
            })
            .catch(err => {
              this.dispatch('alerts/setError', err.response)
            })
        }
      })
      .catch(err => {
        this.dispatch('alerts/setError', err.response)
      })
      .finally(() => commit('SET_LOADING', false))
  },

  async editDevice ({ commit }, request) {
    // console.log('editDevice', request)
    const slug = request.slug
    delete request.slug
    await API.post('/devices/' + slug + '/edit', request)
      .then(async res => {
        await commit('SET_DEVICE', {
          identifier: request.identifier,
          device: res.data.data,
        })
        this.dispatch('alerts/setSuccess', {
          message: 'messages.device_have_been_successfully_edited',
        })
      })
      .catch(err => {
        this.dispatch('alerts/setError', err.response)
      })
  },

  async editDeviceUsers ({ commit }, request) {
    await API.post('/devices/' + request[0] + '/users', request[1])
      .then(res => {
        commit('SET_USERS_DEVICES', res.data.data)
        this.dispatch('alerts/setSuccess', {
          message: 'messages.users_have_been_successfully_edited',
        })
      })
      .catch(err => {
        console.log(err)
        this.dispatch('alerts/setError', err.response)
      })
  },

  searchByInput ({ state }, data) {
    const results = []
    state.devices.devices.forEach(device => {
      if (device && device[data.field]) {
        const field = device[data.field].toLowerCase()
        if (field.includes(data.searchInput)) {
          results.push({
            text: field,
            color: MAIN_COLOR,
            component: 'Device',
            type: `Device's ${data.field}`,
            path: `/devices/${device.slug}/details`,
          })
        }
      }
    })
    return results
  },

  async searchById ({ dispatch }, searchInput) {
    return await dispatch('searchByInput', { searchInput, field: 'identifier' })
  },
}

/**
 * Getters
 */
export const getters = {
  loading: state => state.loading,
  // set the device online status here by the global timer
  devices: state => state.devices,
  onlineDevices: state => Object.values(state.devices).filter(d => d.online),
  count: state => Object.keys(state.devices).length,
  countOnline: (state, getters) => Object.keys(getters.onlineDevices).length,
  device: state => identifier => state.devices[identifier],
  getBySlug: state => slug => {
    const identifier = Object.keys(state.devices).find(
      key => state.devices[key].slug === slug,
    )
    if (state.devices[identifier]) {
      state.devices[identifier].identifier = identifier
    }
    return state.devices[identifier]
  },
  // usersBySlug: (state, g, a, b) => slug => {
  //   console.log('usersBySlug', slug)
  //   // console.log(a, b)
  //   return []
  //   // const ID = state.devices.filter(d => d.slug === slug)[0].identifier
  //   // return state.usersDevices.filter(r => r.device === ID)
  // },
  // online: (state, getters) => getters.devices.filter(d => d.online),
  // countOnline: (state, getters) => getters.online.length,
  // usersDevices: state => state.usersDevices,
  // countUsersById: state => identifier =>
  //   state.usersDevices.filter(r => r.device === identifier).length,
}
