export const state = {
  baseURL: '',
}

export const mutations = {
  SET_BASE_URL (state, url) {
    state.baseURL = url
  },
}

export const actions = {
  setBaseURL ({ commit }, url) {
    commit('SET_BASE_URL', url)
  },
}

export const getters = {
  baseURL: state => state.baseURL,
}
