<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 480.44 391">
    <g id="Layer_2" data-name="Layer 2" :style="`fill:${color}`">
      <g id="Layer_1-2" data-name="Layer 1">
        <path
          class="cls-1"
          d="M173.78,0A86.89,86.89,0,1,1,86.89,86.89,86.87,86.87,0,0,1,173.78,0m86.89,229c0,23-6.09,76.68-47.58,136.63L195.5,260.67l20.42-40.84a375.34,375.34,0,0,0-42.14-2.61,375,375,0,0,0-42.14,2.61l20.42,40.84-17.6,104.91C93,305.63,86.89,252,86.89,229,35,244.16,0,271.53,0,304.11V391H347.56V304.11C347.56,271.53,312.8,244.16,260.67,229Z"
        />
        <path
          class="cls-1"
          d="M374.44,152.5a53,53,0,1,1-53,53,53,53,0,0,1,53-53m53,139.65c0,14.05-3.71,46.78-29,83.35l-10.74-64,12.46-24.91a208.58,208.58,0,0,0-51.41,0l12.45,24.91-10.73,64c-25.31-36.57-29-69.3-29-83.35-31.66,9.28-53,26-53,45.85v53h212V338C480.44,318.13,459.24,301.43,427.44,292.15Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
import CustomIconMixin from '@/mixins/CustomIconMixin'
export default {
  name: 'ClientsGroupIcon',
  mixins: [CustomIconMixin],
}
</script>
