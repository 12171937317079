import Vue from 'vue'
// import axios from 'axios'
// const API = axios.create({
//   baseURL: 'http://127.0.0.1:8000/',
//   headers: { 'X-Requested-With': 'XMLHttpRequest' },
//   withCredentials: true,
// })
import API from '@/api'

const MAIN_COLOR = '#3f51b5'
const TESTING_LOCAL = false

/**
 * Initial state
 */
export const state = {
  users: [],
  loading: true,
}

/**
 * Mutations
 */
export const mutations = {
  SET_USERS (state, users) {
    if (typeof users === 'object') users = Object.values(users)
    users = users.map(user => {
      try {
        user.slug = user.email.split('.').join('~')
        // user.slug = user.email.replaceAll('.', '~')
      } catch (e) {
        console.log(user.email)
        console.log('ERROR!!!', e)
      }
      user.name = user.first_name + ' ' + user.last_name
      return user
    })
    state.users = users
    state.loading = false
  },

  UNSET_USERS (state) {
    state.users = null
  },

  SET_USER (state, res) {
    const user = res[1]
    user.slug = user.email.split('.').join('~')
    // user.slug = user.email.replaceAll('.', '~')
    user.name = user.first_name + ' ' + user.last_name
    const index = state.users.findIndex(user => user.slug === res[0])
    Vue.set(state.users, index, res[1])
  },

  NEW_USER (state, data) {
    // console.log('data', data[0])
    const user = data[0].data
    user.slug = user.email.split('.').join('~')
    // user.slug = user.email.replaceAll('.', '~')
    user.name = user.first_name + ' ' + user.last_name
    // console.log('USER', user)
    state.users.push(user)
    // console.log('NEW_USER', data)
  },
}

/**
 * Actions
 */
export const actions = {
  async get ({ commit }) {
    if (TESTING_LOCAL) {
      const users = localStorage.getItem('users')
      if (users) {
        return commit('SET_USERS', JSON.parse(users))
      }
    }

    API.get('/users')
      .then(res => {
        commit('SET_USERS', res.data.data)
        if (TESTING_LOCAL) {
          localStorage.setItem('users', JSON.stringify(res.data.data))
        }
      })
      .catch(err => {
        console.log(err)
        this.dispatch('alerts/setError', err.response)
      })
  },

  async editUser ({ commit }, user) {
    await API.post(`/users/${user.slug}/edit`, user)
      .then(async res => {
        await commit('SET_USER', [user.slug, res.data.data])
        if (user.user === user.email) {
          this.dispatch('alerts/setSuccess', {
            message: 'The user has been edited successfully',
          })
        }
      })
      .catch(err => {
        this.dispatch('alerts/setError', err.response)
        return false
      })
    return true
  },

  async createUser ({ commit }, data) {
    await API.post('/users/create', data)
      .then(async res => {
        await commit('NEW_USER', [res.data])
        this.dispatch('alerts/setSuccess', {
          message: 'The user has been created successfully',
        })
      })
      .catch(err => {
        this.dispatch('alerts/setError', err.response)
      })
  },

  async updateUser ({ commit }, { slug, email }) {
    await API.get('/users/user?email=' + email)
      .then(async res => {
        if (res.data.data && res.data.data.email) {
          await commit('SET_USER', [slug, res.data.data])
          this.dispatch('alerts/setSuccess', {
            message: 'The user has been updated successfully',
          })
        }
      })
      .catch(err => {
        console.log(err)
      })
  },

  searchByInput ({ state }, data) {
    const results = []
    state.users.forEach(user => {
      if (user && user[data.field]) {
        const field = user[data.field].toLowerCase()
        if (field.includes(data.searchInput)) {
          const text = data.field === 'name' ? field : field + ` (${user.name})`
          results.push({
            text: text,
            color: MAIN_COLOR,
            component: 'User',
            type: `User's ${data.field}`,
            path: `/users/${user.slug}/details`,
          })
        }
      }
    })
    return results
  },

  async searchByEmail ({ dispatch }, searchInput) {
    return await dispatch('searchByInput', { searchInput, field: 'email' })
  },

  async searchByName ({ dispatch }, searchInput) {
    return await dispatch('searchByInput', { searchInput, field: 'name' })
  },

  async searchByPhone ({ dispatch }, searchInput) {
    return await dispatch('searchByInput', { searchInput, field: 'phone' })
  },
}

/**
 * Getters
 */
export const getters = {
  loading: state => state.loading,
  users: state => state.users,
  count: state => state.users.length,
  user: state => email => state.users.find(u => u.email === email),
  byClient: state => code => {
    return state.users.filter(u => u.client == code)
  },
  getBySlug: state => slug => state.users.filter(u => u.slug === slug)[0],
  usersDevices: (state, getters, rootState) => rootState.devices.usersDevices,
  userDevices: (state, getters) => slug => {
    return getters.usersDevices.filter(
      relation => relation.user_email === slug.split('~').join('.'),
    )
    // relation.user_email === slug.replace('---', '@').replaceAll('~', '.'),
  },
  usersByDevice: (state, getters, rootState, rootGetters) => identifier => {
    // console.log('usersByDevice -> identifier', identifier)
    const deviceUsers = rootGetters['usersDevices/getByDevice'](identifier)
    const users = []
    for (const email in deviceUsers) {
      const user = { ...deviceUsers[email] }
      user.user = state.users.find(u => u.email === email)
      users.push(user)
    }
    // console.log('users', users)
    return users
    // return rootGetters['devices/usersBySlug'](slug).map(relation => {
    //   relation.user = state.users.filter(
    //     u => u.email === relation.user_email,
    //   )[0]
    //   return relation
    // })
  },
  devices: (state, getters) => slug => {
    console.log('usersDevices', getters.usersDevices)
    // const user = state.users.filter(u => u.slug === slug)[0]
    // const deviceList = rootState.devices.users_devices.filter(relation => {
    //   return relation.user === user.email
    // })
    // // .map(relation => {
    //   relation.device = rootState.devices.devices.filter(device => {
    //     console.log(device.identifier, relation.device)
    //     return device.identifier === relation.device
    //   })
    //   return relation
    // })
    // console.log('deviceList', deviceList)
    return []
  },
}
